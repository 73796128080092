import React from 'react'
import { PageProps, graphql, Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { PostPageQuery } from '../../graphql-types'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PostArticle from '../components/organisms/PostArticle'

const PostPage: React.FC<PageProps<PostPageQuery>> = ({ data, pageContext }) => {
  const post = data.markdownRemark!
  return (
    <Layout>
      <SEO
        title={post.frontmatter?.title || ''}
        description={post.excerpt}
      />
      <PostArticle post={post}></PostArticle>
      <ul className="list-none ml-0 grid grid-cols-2">
        <li>
          {pageContext.nextPagePath && (
            <Link
              to={pageContext.nextPagePath}
              className="flex items-center"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className="truncate mx-2">
                prev: {pageContext.nextItem.node.frontmatter.title}
              </span>
            </Link>
          )}
        </li>
        <li className="text-right">
          {pageContext.previousPagePath && (
            <Link
              to={pageContext.previousPagePath}
              className="flex items-center flex-row-reverse"
            >
              <FontAwesomeIcon icon={faChevronRight} />
              <span className="truncate mx-2">
                next: {pageContext.previousItem.node.frontmatter.title}
              </span>
            </Link>
          )}
        </li>
      </ul>
    </Layout>
  )
}

export default PostPage

export const pageQuery = graphql`
  query PostPage ($pageId: String!) {
    markdownRemark(
      id: {eq: $pageId}
    ) {
      fields {
        slug
      }
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        title
      }
      html
      excerpt(format: PLAIN)
    }
  }
`
